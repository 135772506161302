import React from "react";
import bannerImage from "../images/sliders/Bowling_6.png"; // Use your banner image

const About = () => {
  return (
    <div>
      {/* Banner Section */}
      <div
        className="relative w-full h-96"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay Text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-red-600 py-4 px-8 text-white font-bold text-4xl">
            Contact Us
          </div>
        </div>
      </div>

      {/* About Content */}
      <div className="max-w-screen-lg mx-auto my-12 p-4">
        <div>
          {" "}
          <p>General Questions or concerns</p>
          <p>
            Email:{" "}
            <a
              href="mailto:info@knockoutschicagoleague.com"
              className="text-blue-300 hover:underline"
            >
              info@knockoutschicagoleague.com
            </a>
          </p>
          <p>
            Phone:{" "}
            <a
              href="tel:312-361-4157"
              className="text-blue-300 hover:underline"
            >
              312-361-4157
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
