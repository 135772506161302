import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Slider from "./components/Slider";
import GridSection from "./components/GridSection";
import Footer from "./components/Footer";
import BowlingHour from "./components/BowlingHour";
import AdditionalInfo from "./components/AdditionalInfo";
import LeagueRules from "./pages/LeagueRules";
import About from "./pages/About";
import GeneralInfo from "./pages/GeneralInfo";
import Contact from "./pages/Contact";
import RegistrationBanner from "./components/RegistrationBanner"; // Import the new Registration Banner

function App() {
  return (
    <Router>
      <div className="min-h-screen">
        <Header /> {/* Header will be consistent across all pages */}
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />{" "}
            {/* Default homepage route */}
            <Route path="/league-rules" element={<LeagueRules />} />{" "}
            <Route path="/about" element={<About />} />
            <Route path="/general-info" element={<GeneralInfo />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer /> {/* Footer will be consistent across all pages */}
      </div>
    </Router>
  );
}

// HomePage component to wrap the main content of your home page
const HomePage = () => (
  <div>
    <Slider />
    {/* <GridSection /> */}
    <BowlingHour />
    {/* <RegistrationBanner /> */}
    <AdditionalInfo />
  </div>
);

export default App;
