import React from "react";
import { Link } from "react-router-dom";
import leagues from "../images/additionalinfo/leagues.jpg";
import events from "../images/additionalinfo/private.jpg";
import tournaments from "../images/additionalinfo/tournaments.jpg";

const AdditionalInfo = () => {
  return (
    <div className="max-w-screen-lg mx-auto my-12 grid grid-cols-1 md:grid-cols-3 gap-8 p-4">
      {/* Item 1 - Leagues */}
      <div className="bg-white shadow-lg">
        <img
          src={leagues}
          alt="Leagues"
          className="w-full h-auto object-cover"
          style={{ width: "600px", height: "400px" }}
        />
        <div className="p-4">
          <h3 className="text-xl font-bold text-red-600 mb-2">Leagues</h3>
          <p className="text-gray-600 mb-4">
            Join our leagues and experience the joy of friendly competition with
            other members. It's the perfect way to bond, improve your skills,
            and have fun!
          </p>
          <Link to="/league-rules">
            <button className="bg-white border border-black py-2 px-4 hover:bg-gray-100">
              Read More
            </button>
          </Link>
        </div>
      </div>

      {/* Item 2 - Events */}
      <div className="bg-white shadow-lg">
        <img
          src={events}
          alt="Events"
          className="w-full h-auto object-cover"
          style={{ width: "600px", height: "400px" }}
        />
        <div className="p-4">
          <h3 className="text-xl font-bold text-red-600 mb-2">Events</h3>
          <p className="text-gray-600 mb-4">
            A banquet dinner will be planned after the season to celebrate, give
            out awards, and enjoy time together. Stay connected for event
            updates!
          </p>
          <Link to="/league-rules">
            <button className="bg-white border border-black py-2 px-4 hover:bg-gray-100">
              Read More
            </button>
          </Link>
        </div>
      </div>

      {/* Item 3 - Prizes */}
      <div className="bg-white shadow-lg">
        <img
          src={tournaments}
          alt="Prizes"
          className="w-full h-auto object-cover"
          style={{ width: "600px", height: "400px" }}
        />
        <div className="p-4">
          <h3 className="text-xl font-bold text-red-600 mb-2">Prizes</h3>
          <p className="text-gray-600 mb-4">
            We offer exciting prizes including Raffles, Gift Cards, Trivia, and
            various drawings to keep the competition fun and engaging throughout
            the season.
          </p>
          <Link to="/league-rules">
            <button className="bg-white border border-black py-2 px-4 hover:bg-gray-100">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
