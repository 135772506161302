import React from "react";
import bannerImage from "../images/sliders/Bowling_6.png"; // Use your banner image

const LeagueRules = () => {
  return (
    <div>
      {/* Banner */}
      <div
        className="relative w-full h-96"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay Text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-red-600 py-4 px-8 text-white font-bold text-4xl">
            League Rules
          </div>
        </div>
      </div>

      {/* League Rules Content */}
      <div className="max-w-screen-lg mx-auto my-12 p-4">
        <h2 className="text-2xl font-semibold mb-4">
          Knockouts Men’s Pride League
        </h2>
        <p className="mb-6">Our Code of Conduct</p>

        <h3 className="text-xl font-bold mt-6 mb-2">Founder / President</h3>
        <p>Randy Warzinski</p>

        <h3 className="text-xl font-bold mt-6 mb-2">
          Vice President / General League Administrator
        </h3>
        {/* <p>To be announced</p> */}

        <h3 className="text-xl font-bold mt-6 mb-2">Background</h3>
        <p>
          Knockouts is a casual men’s only bowling league who identify as
          bisexual, straight, or gay.
        </p>

        <h3 className="text-xl font-bold mt-6 mb-2">Rules</h3>

        <ul className="list-disc list-inside">
          <li>
            <strong>League Schedule:</strong> Knockouts runs 14 weeks during the
            Winter, Fall, and summer season.
          </li>
          <li>
            <strong>Payment and Fees:</strong> The total price for all seasons
            is $340.00. + a mandatory 20.00 one-time administrative league fee
            per bowler. Payment is required in full by no later than 60 days. We
            ask that you pay in full at start of season. If you are unable to
            pay in full, we require half of the full amount due and the rest of
            the remaining amount within 60 days for the scheduled league season.
            We accept cash or electronic payments only (Zelle, Apple Pay, and
            Cash App). Checks, Money Orders, or Debit/Credit Cards will not be
            accepted. If full payment is not received by the 60 th day of
            bowling it will be grounds for dismissal for the remaining season of
            the league. No exceptions! All payments given to the league are
            final and no refunds will be given at any time.
          </li>
          <li>
            <strong>Age Requirement:</strong> Minimum Age is 21 because alcohol
            is served and maybe consumed during bowling hours.
          </li>
          <li>
            <strong>Absenteeism:</strong> If a bowler misses more than 2 times
            of league bowling, we will ask that bowler to resign from their team
            and league all together.
          </li>
          <li>
            <strong>Tardiness:</strong> If a bowler is showing up late over 20
            minutes after practice stops more than 3 times, we will ask that
            bowler to resign from their team and the league all together.
          </li>
          <li>
            <strong>Pre-bowling:</strong> As a courtesy we allow pre-bowling the
            day before the scheduled day of bowling 3 times only during the
            league season.
          </li>
          <li>
            <strong>Events:</strong> A banquet dinner will be planned after the
            season.
          </li>
          <li>
            <strong>Prizes:</strong> The league does Raffles, Gift Cards,
            Trivia, and Various Drawings during the league season.
          </li>
          <li>
            <strong>Location:</strong> The location of which bowling takes place
            is subject to change at any point when season starts based upon
            business needs.
          </li>
        </ul>

        <h3 className="text-xl font-bold mt-6 mb-2">Code of Conduct</h3>
        <p>
          While we pride ourselves on having a fun time each and every time, we
          get together there does have to be a code of conduct put into place
          for legal reasons. This is to protect us and for the overall general
          safety of each bowler on our league. This also is put into place for
          legal reasons.
        </p>

        <ul className="list-disc list-inside mt-4 space-y-2">
          <li>
            Unwanted behavior of any kind will not be tolerated any time during
            the scheduled time of bowling on the league. This could include
            solicitations or advances of any kind. This is grounds for immediate
            dismissal and permanent removal from the league.
          </li>
          <li>
            Racism of any kind will not be tolerated any time during the
            duration of the league season. Any racist jokes, comments, slurs,
            and innuendos of any kind during the league season are grounds for
            immediate dismissal and permanent removal from the league.
          </li>
          <li>
            Discrimination of any kind due to disability will not be tolerated
            any time during the time of bowling on the league and will be
            grounds for immediate dismissal and permanent removal from the
            league.
          </li>
          <li>
            Cheating or Score Manipulation will not be tolerated any time during
            the time of bowling on the league. Any bowler who is caught doing
            this will be immediately dismissed and permanently removed from the
            league.
          </li>
          <li>
            Hostility towards any bowler will not be tolerated at any time
            during the league seasons and is grounds for immediate dismissal and
            permanent removal from the league.
          </li>
          <li>
            This is a men’s only league. Women nor transgendered males or
            females are not permitted any point during the league season. No
            exceptions!
          </li>
          <li>
            Homophobia of any kind will not be tolerated at any point during the
            bowling league season and is grounds for immediate dismissal and
            permanent removal from the league.
          </li>
          <li>
            Harassment or Bullying against any bowler on the league will not be
            tolerated at any time during the league seasons and is grounds for
            immediate dismissal and permanent removal from the league.
          </li>
        </ul>

        <p className="mt-6">
          If you have any questions or concerns, please contact us at{" "}
          <a
            href="mailto:info@knockoutschicagoleague.com"
            className="text-blue-500 hover:underline"
          >
            info@knockoutschicagoleague.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default LeagueRules;
