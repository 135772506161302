import React from "react";
import { FaFacebook } from "react-icons/fa"; // Import the icons
import logo from "../images/logo/logo2.jpeg"; // Import the logo
import { Link } from "react-router-dom"; // Import React Router's Link component

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Get In Touch Section */}
        <div>
          <img src={logo} alt="Logo" className="w-32 h-auto mb-4" />{" "}
          {/* Logo */}
          <h5 className="text-lg mb-2">Get In Touch</h5>
          <p>Address: 3111 River Rd River Grove, IL 60171</p>
          <p>
            Email:{" "}
            <a
              href="mailto:info@knockoutschicagoleague.com"
              className="text-blue-300 hover:underline"
            >
              info@knockoutschicagoleague.com
            </a>
          </p>
          <p>
            Phone:{" "}
            <a
              href="tel:312-361-4157"
              className="text-blue-300 hover:underline"
            >
              312-361-4157
            </a>
          </p>
        </div>

        {/* Quick Links Section */}
        <div>
          <h5 className="text-lg mb-2">Quick Links</h5>
          <ul>
            <li>
              <Link
                to="/league-rules"
                className="text-blue-300 hover:underline"
              >
                League Rules
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-blue-300 hover:underline">
                About
              </Link>
            </li>
            <li>
              <Link
                to="/general-info"
                className="text-blue-300 hover:underline"
              >
                General Info
              </Link>
            </li>
            <li>
              <Link to="/contact" className="text-blue-300 hover:underline">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* Social Media Section with Icons */}
        <div>
          <h5 className="text-lg mb-2">Follow Us</h5>
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/KnockoutsChicagoLeague"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-400"
            >
              <FaFacebook className="inline-block w-6 h-6" />{" "}
              {/* Facebook Icon */}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
