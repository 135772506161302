import React from "react";
import { Link } from "react-router-dom";
import team from "../images/bowlinghour/team.jpg";

const BowlingHour = () => {
  return (
    <div className="max-w-screen-lg mx-auto my-12 p-4 grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-8">
      {/* Left Column - About Us */}
      <div>
        <h3 className="text-2xl font-bold mb-4">
          About Us
          <span className="block h-1 w-12 bg-red-600 mt-2"></span>
        </h3>
        {/* <div className="mb-4">
          <img src={team} alt="About Us" className="w-480 h-480 object-cover" />
        </div> */}
        <p className="text-gray-600 mb-4">
          Knockouts is a casual men's only bowling league for those who identify
          as gay, bisexual, or straight. We are a brand new league that has just
          broke ground so there will be many updates along the way. We are
          looking to get as many members as possible to come and join the
          league. If you are looking for a pride bowling league that only plays
          showtunes and music from the 60's and 70's then this wouldn't be for
          you. We hope to see you in November this year!
        </p>
        <Link to="/about">
          <button className="bg-white border border-black py-2 px-4 hover:bg-gray-100">
            Read More
          </button>
        </Link>
      </div>

      {/* Right Column - Bowling Hours */}
      {/* <div>
        <h3 className="text-2xl font-bold mb-4">
          Bowling Hours
          <span className="block h-1 w-12 bg-red-600 mt-2"></span>
        </h3>
        <ul className="mb-8">
          <li className="flex justify-between py-2 border-b">
            <span>Monday</span>
            <span>4:00 pm - 11:00 pm</span>
          </li>
          <li className="flex justify-between py-2 border-b">
            <span>Tuesday</span>
            <span>4:00 pm - Midnight</span>
          </li>
          <li className="flex justify-between py-2 border-b">
            <span>Wednesday</span>
            <span>4:00 pm - 11:00 pm</span>
          </li>
          <li className="flex justify-between py-2 border-b">
            <span>Thursday</span>
            <span>4:00 pm - 11:00 pm</span>
          </li>
          <li className="flex justify-between py-2 border-b">
            <span>Friday</span>
            <span>Noon - Midnight</span>
          </li>
          <li className="flex justify-between py-2 border-b">
            <span>Saturday</span>
            <span>11:00 am - 1:00 am</span>
          </li>
          <li className="flex justify-between py-2">
            <span>Sunday</span>
            <span>11:00 am - 10:00 pm</span>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default BowlingHour;
