import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { FaFacebook } from "react-icons/fa"; // Import the Facebook and Instagram icons
import logo from "../images/logo/logo.png";

const Header = () => {
  return (
    <header className="bg-gray-200 text-black p-4">
      {/* Light background for visibility */}
      <div className="flex justify-between items-center mx-auto max-w-screen-lg">
        {/* Logo */}
        <div className="logo">
          <Link to="/">
            {" "}
            {/* Link to the homepage */}
            <img src={logo} alt="Logo" className="w-48 h-auto" />
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="flex space-x-8">
          <Link
            to="/league-rules"
            className="text-black text-lg hover:text-gray-600"
          >
            League Rules
          </Link>
          <Link to="/about" className="text-black text-lg hover:text-gray-600">
            About
          </Link>
          <Link
            to="/general-info"
            className="text-black text-lg hover:text-gray-600"
          >
            General Info
          </Link>
          <Link
            to="/contact"
            className="text-black text-lg hover:text-gray-600"
          >
            Contact
          </Link>
        </div>

        {/* Social Icons */}
        <div className="social-icons flex space-x-4">
          <a
            href="https://www.facebook.com/KnockoutsChicagoLeague"
            target="_blank"
            aria-label="Facebook"
          >
            <FaFacebook className="w-8 h-8 text-blue-600" />{" "}
            {/* Facebook Icon with color */}
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
