import React from "react";
import SliderSlick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image_1 from "../images/sliders/Bowling_0.png";
import image_2 from "../images/sliders/Bowling_1.jpg";
import image_3 from "../images/sliders/Bowling_2.jpg";

const Slider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="relative slider-container">
      {/* Static Text Overlay */}
      <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black bg-opacity-50 text-center p-4">
        <h1 className="text-white text-3xl md:text-5xl font-bold mb-4">
          Registration Here for Winter Season
        </h1>
        <p className="text-white text-lg md:text-2xl mb-2">
          November 4th At 7:30PM
        </p>
        <p className="text-white text-lg md:text-2xl mb-4">
          Bowlero River Grove
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScGBEq0cR6Nkkvzm3jHf-IBDBfV_okBdRtcEHFz0NZCe3pG3Q/viewform" // Replace with your Google Form URL
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-red-600 text-white py-2 px-6 rounded-md hover:bg-red-700">
            Register Now
          </button>
        </a>
      </div>

      {/* Image Slider */}
      <SliderSlick {...settings} className="z-0">
        <div>
          <img
            src={image_1}
            alt="Slide 1"
            className="w-full h-[500px] object-cover"
            style={{ height: "500px", width: "1800px" }}
          />
        </div>
        <div>
          <img
            src={image_2}
            alt="Slide 2"
            className="w-full h-[500px] object-cover"
            style={{ height: "500px", width: "1800px" }}
          />
        </div>
        <div>
          <img
            src={image_3}
            alt="Slide 3"
            className="w-full h-[500px] object-cover"
            style={{ height: "500px", width: "1800px" }}
          />
        </div>
      </SliderSlick>
    </div>
  );
};

export default Slider;
