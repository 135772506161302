import React from "react";
import bannerImage from "../images/sliders/Bowling_6.png"; // Use your banner image

const About = () => {
  return (
    <div>
      {/* Banner Section */}
      <div
        className="relative w-full h-96"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay Text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-red-600 py-4 px-8 text-white font-bold text-4xl">
            About Us
          </div>
        </div>
      </div>

      {/* About Content */}
      <div className="max-w-screen-lg mx-auto my-12 p-4">
        <h2 className="text-2xl font-semibold mb-4">Who We Are</h2>
        <p className="mb-6">
          Knockouts is a casual men's only bowling league for those who identify
          as gay, bisexual, or straight. We are a brand new league that has just
          broke ground so there will be many updates along the way. We are
          looking to get as many members as possible to come and join the
          league. If you are looking for a pride bowling league that only plays
          showtunes and music from the 60's and 70's then this wouldn't be for
          you. We hope to see you in November this year!
        </p>
        <p>Randy Warzinski</p>
        <p>Founder and President</p>

        <h3 className="text-xl font-bold mt-6 mb-2">Get Involved</h3>
        <p>
          If you're interested in joining the Knockouts Men’s Pride League or
          learning more about us, feel free to reach out! Contact us at{" "}
          <a
            href="mailto:info@knockoutschicagoleague.com"
            className="text-blue-500 hover:underline"
          >
            info@knockoutschicagoleague.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
