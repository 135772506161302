import React from "react";
import bannerImage from "../images/sliders/Bowling_6.png"; // Use your banner image

const GeneralInfo = () => {
  return (
    <div>
      {/* Banner Section */}
      <div
        className="relative w-full h-96"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay Text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-red-600 py-4 px-8 text-white font-bold text-4xl">
            General Information
          </div>
        </div>
      </div>

      {/* About Content */}
      <div className="max-w-screen-lg mx-auto my-12 p-4">
        <p className="mb-6">
          Knockouts Men’s Pride League meets on Monday evenings at Bowlero River
          Grove by O'Hare Airport. We start bowling at 7:30 PM. The League is 14
          weeks starting November 4th. The minimum age is 21 to bowl on our
          league due to alcoholic beverages that are served. During the season
          we hold various events, fundraisers, and prizes
        </p>

        <p className="mb-6">
          The total cost for the Winter season is $360. The total amount is due
          at the time of the first day of bowling for the Winter season. If
          members are not able to pay the full amount, half of the full amount
          would be required. The remaining amount of league fees are due within
          60 days. No exceptions!
        </p>
      </div>
    </div>
  );
};

export default GeneralInfo;
